import React from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import UserAuthContainer from '../containers/auth/user-auth';
import PublicLayout from '../layout/public-layout';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function ForgotPassword({ location }: RouteComponentProps<{}>) {
  const { pathname } = location || {};
  return (
    <PublicLayout>
      <Container>
        <UserAuthContainer pathname={pathname || ''} />
      </Container>
    </PublicLayout>
  );
}
